import React from 'react';
import './App.css';

function App() {
  const handleRedirect = () => {
    window.location.href = 'https://book.flynchill.com';
  };

  return (
    <div className="App">
      <div className="TPWL-header-content">
        <div className="TPWL-header-content-left">
          <div className="TPWL-header-content__logo">
            <a className="TPWL-header-content-logo-link" href="/">
              <img src="/logo.png" alt="Fly'n'Chill Logo" className="TPWL-header-content-logo__img" />
              <span className="TPWL-header-content-logo__text">Fly'n'Chill</span>
            </a>
          </div>
        </div>
        <div className="TPWL-header-content__description">
          Travel simply and economically!
        </div>
      </div>
      <div className="TPWL-header-content__label-wrapper">
        <div className="TPWL-header-content__label">
          Cheap Flights & Hotel Search
        </div>
      </div>
      <button className="main-button" onClick={handleRedirect}>
        Book Now
      </button>
    </div>
  );
}

export default App;
